import React from "react"
import {Container, Row, Col, Button, Form, FormGroup, Input} from "reactstrap"
import {colors, fonts} from "../../theme.js"

export default class JoinThe extends React.Component {
  state = {
    languageSelect: "",
    emailAddress: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    alert(`Welcome ${this.state.languageSelect} ${this.state.emailAddress}!`)
  }

  render() {
    return (
      <Container fluid={true} className="py-4 jointheform">
        <Row>
          <Col className="jointhecol" xs="12" sm={{size: 9, offset: 1}}>
            <h3 className="text-center">
              Get updates when we add new words and customs
              <br />
              <span style={{color: colors.meatRare}}>SUBSCRIBE:</span>
            </h3>
            <Form
              inline
              action="https://www.getdrip.com/forms/XXX/submissions"
              method="post"
              data-drip-embedded-form="XXX">
              <FormGroup className="mx-md-2">
                <Input
                  type="email"
                  name="fields[email]"
                  id="drip-email"
                  aria-label="Email"
                  placeholder="Enter your email..."
                />
              </FormGroup>
              <FormGroup className="mx-md-2">
                <Input
                  type="select"
                  name="fields[language]"
                  id="drip-language"
                  aria-label="Selection">
                  <option disabled="disabled" selected>
                    Your main interest...
                  </option>

                  <option value="etymology">Etymology (Word origins)</option>
                  <option value="customs">Culture and customs</option>
                </Input>
              </FormGroup>

              <Button
                data-drip-attribute="sign-up-button"
                className="joinsubmit">
                Subscribe
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}
